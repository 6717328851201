const STARR_DEFAULT_CASES = [
  {
    "Date": "3/25",
    "Count": 0,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "3/26",
    "Count": 3,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 3,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "3/30",
    "Count": 2,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 2,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/04",
    "Count": 1,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 1,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 1,
      "Escobares": 0
    }
  },
  {
    "Date": "4/05",
    "Count": 1,
    "Gender": {
      "Male": 1,
      "Female": 0
    },
    "Transmission": {
      "Travel": 1,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 1,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 1,
      "Escobares": 0
    }
  },
  {
    "Date": "4/27",
    "Count": 1,
    "Gender": {
      "Male": 1,
      "Female": 0
    },
    "Transmission": {
      "Travel": 1,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 1,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/29",
    "Count": 1,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/04",
    "Count": 1,
    "Gender": {
      "Male": 1,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 1,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 1,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/07",
    "Count": 4,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 4
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/12",
    "Count": 1,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 1
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/14",
    "Count": 1,
    "Gender": {
      "Male": 1,
      "Female": 0
    },
    "Transmission": {
      "Travel": 1,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 1,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 1,
      "Escobares": 0
    }
  },
  {
    "Date": "5/15",
    "Count": 1,
    "Gender": {
      "Male": 1,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 1,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 1
    }
  },
  {
    "Date": "5/18",
    "Count": 4,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  }
];

export default STARR_DEFAULT_CASES;
