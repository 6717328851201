const DEFAULT_CORONA_DATA = [
  {
      "Date": "3/18",
      "Count": 0,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 0,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 0,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 0,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/19",
      "Count": 1,
      "Cases": 1,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 0,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 0,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 0,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/20",
      "Count": 2,
      "Cases": 1,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 0,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 0,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 0,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/21",
      "Count": 6,
      "Cases": 4,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 1,
      "CasesHidalgo": 1,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 0,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 0,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/22",
      "Count": 6,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2,
      "CasesHidalgo": 1,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 0,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 0,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/23",
      "Count": 6,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 0,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 0,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/24",
      "Count": 7,
      "Cases": 1,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 5,
      "CasesHidalgo": 3,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 0,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 0,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/25",
      "Count": 10,
      "Cases": 3,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 8,
      "CasesHidalgo": 3,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 0,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 0,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/26",
      "Count": 13,
      "Cases": 3,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 11,
      "CasesHidalgo": 3,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 3,
      "CasesStarr": 3,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 1,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/27",
      "Count": 13,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 18,
      "CasesHidalgo": 7,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 3,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 1,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/28",
      "Count": 20,
      "Cases": 7,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 27,
      "CasesHidalgo": 9,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 3,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 1,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/29",
      "Count": 20,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 28,
      "CasesHidalgo": 1,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 3,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 1,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/30",
      "Count": 26,
      "Cases": 6,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 38,
      "CasesHidalgo": 10,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 5,
      "CasesStarr": 2,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 1,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/31",
      "Count": 26,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 46,
      "CasesHidalgo": 8,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 5,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/01",
      "Count": 37,
      "Cases": 11,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 62,
      "CasesHidalgo": 16,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 5,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/02",
      "Count": 55,
      "Cases": 18,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 79,
      "CasesHidalgo": 17,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 5,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 4,
      "CasesWillacy": 2,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/03",
      "Count": 52,
      "Cases": 7,
      "Deaths": 0,
      "Recoveries": 10,
      "CountHidalgo": 86,
      "CasesHidalgo": 7,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 5,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/04",
      "Count": 66,
      "Cases": 15,
      "Deaths": 0,
      "Recoveries": 1,
      "CountHidalgo": 86,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 6,
      "CasesStarr": 1,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 1,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/05",
      "Count": 76,
      "Cases": 10,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 99,
      "CasesHidalgo": 13,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 7,
      "CasesStarr": 1,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/06",
      "Count": 89,
      "Cases": 14,
      "Deaths": 1,
      "Recoveries": 0,
      "CountHidalgo": 113,
      "CasesHidalgo": 14,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/07",
      "Count": 89,
      "Cases": 7,
      "Deaths": 0,
      "Recoveries": 7,
      "CountHidalgo": 127,
      "CasesHidalgo": 15,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 0,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/08",
      "Count": 94,
      "Cases": 10,
      "Deaths": 0,
      "Recoveries": 5,
      "CountHidalgo": 130,
      "CasesHidalgo": 11,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 8,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 4,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/09",
      "Count": 99,
      "Cases": 29,
      "Deaths": 2,
      "Recoveries": 22,
      "CountHidalgo": 136,
      "CasesHidalgo": 7,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 1,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/10",
      "Count": 111,
      "Cases": 12,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 161,
      "CasesHidalgo": 35,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 10,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/11",
      "Count": 130,
      "Cases": 36,
      "Deaths": 0,
      "Recoveries": 17,
      "CountHidalgo": 168,
      "CasesHidalgo": 7,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/12",
      "Count": 130,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 170,
      "CasesHidalgo": 2,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/13",
      "Count": 143,
      "Cases": 21,
      "Deaths": 0,
      "Recoveries": 8,
      "CountHidalgo": 167,
      "CasesHidalgo": 8,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 11,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/14",
      "Count": 163,
      "Cases": 23,
      "Deaths": 0,
      "Recoveries": 3,
      "CountHidalgo": 179,
      "CasesHidalgo": 19,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 7,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/15",
      "Count": 167,
      "Cases": 15,
      "Deaths": 0,
      "Recoveries": 11,
      "CountHidalgo": 184,
      "CasesHidalgo": 8,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 2,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 6,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/16",
      "Count": 170,
      "Cases": 16,
      "Deaths": 1,
      "Recoveries": 12,
      "CountHidalgo": 190,
      "CasesHidalgo": 10,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 3,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 2,
      "CountWillacy": 6,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/17",
      "Count": 172,
      "Cases": 9,
      "Deaths": 1,
      "Recoveries": 6,
      "CountHidalgo": 194,
      "CasesHidalgo": 7,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 3,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 4,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 3
  },
  {
      "Date": "4/18",
      "Count": 184,
      "Cases": 19,
      "Deaths": 1,
      "Recoveries": 6,
      "CountHidalgo": 207,
      "CasesHidalgo": 13,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 4,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/19",
      "Count": 184,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 213,
      "CasesHidalgo": 6,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 4,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/20",
      "Count": 187,
      "Cases": 12,
      "Deaths": 1,
      "Recoveries": 8,
      "CountHidalgo": 206,
      "CasesHidalgo": 7,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 14,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 2,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 1
  },
  {
      "Date": "4/21",
      "Count": 185,
      "Cases": 11,
      "Deaths": 2,
      "Recoveries": 11,
      "CountHidalgo": 193,
      "CasesHidalgo": 7,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 20,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/22",
      "Count": 173,
      "Cases": 12,
      "Deaths": 3,
      "Recoveries": 21,
      "CountHidalgo": 181,
      "CasesHidalgo": 6,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 17,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/23",
      "Count": 177,
      "Cases": 11,
      "Deaths": 2,
      "Recoveries": 5,
      "CountHidalgo": 167,
      "CasesHidalgo": 9,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 23,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/24",
      "Count": 181,
      "Cases": 10,
      "Deaths": 0,
      "Recoveries": 6,
      "CountHidalgo": 160,
      "CasesHidalgo": 9,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 16,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 5,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/25",
      "Count": 193,
      "Cases": 12,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 164,
      "CasesHidalgo": 4,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 3,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/26",
      "Count": 193,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 169,
      "CasesHidalgo": 5,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 7,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/27",
      "Count": 199,
      "Cases": 10,
      "Deaths": 0,
      "Recoveries": 4,
      "CountHidalgo": 150,
      "CasesHidalgo": 2,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 20,
      "CountStarr": 2,
      "CasesStarr": 1,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/28",
      "Count": 200,
      "Cases": 11,
      "Deaths": 2,
      "Recoveries": 8,
      "CountHidalgo": 147,
      "CasesHidalgo": 5,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 7,
      "CountStarr": 2,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/29",
      "Count": 206,
      "Cases": 14,
      "Deaths": 1,
      "Recoveries": 7,
      "CountHidalgo": 136,
      "CasesHidalgo": 9,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 20,
      "CountStarr": 3,
      "CasesStarr": 1,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/30",
      "Count": 207,
      "Cases": 11,
      "Deaths": 1,
      "Recoveries": 9,
      "CountHidalgo": 133,
      "CasesHidalgo": 10,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 13,
      "CountStarr": 3,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/01",
      "Count": 212,
      "Cases": 9,
      "Deaths": 0,
      "Recoveries": 4,
      "CountHidalgo": 137,
      "CasesHidalgo": 5,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 1,
      "CountStarr": 3,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/02",
      "Count": 212,
      "Cases": 11,
      "Deaths": 0,
      "Recoveries": 11,
      "CountHidalgo": 138,
      "CasesHidalgo": 1,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 3,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/03",
      "Count": 212,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 139,
      "CasesHidalgo": 1,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 3,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/04",
      "Count": 221,
      "Cases": 11,
      "Deaths": 0,
      "Recoveries": 2,
      "CountHidalgo": 143,
      "CasesHidalgo": 12,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 7,
      "CountStarr": 4,
      "CasesStarr": 1,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/05",
      "Count": 222,
      "Cases": 11,
      "Deaths": 0,
      "Recoveries": 10,
      "CountHidalgo": 143,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 12,
      "CountStarr": 4,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/06",
      "Count": 210,
      "Cases": 15,
      "Deaths": 1,
      "Recoveries": 26,
      "CountHidalgo": 136,
      "CasesHidalgo": 6,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 1,
      "CountStarr": 4,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/07",
      "Count": 216,
      "Cases": 14,
      "Deaths": 1,
      "Recoveries": 7,
      "CountHidalgo": 143,
      "CasesHidalgo": 9,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 2,
      "CountStarr": 8,
      "CasesStarr": 4,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 8,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/08",
      "Count": 216,
      "Cases": 11,
      "Deaths": 2,
      "Recoveries": 9,
      "CountHidalgo": 152,
      "CasesHidalgo": 9,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 8,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 7
  },
  {
      "Date": "5/09",
      "Count": 225,
      "Cases": 18,
      "Deaths": 0,
      "Recoveries": 9,
      "CountHidalgo": 161,
      "CasesHidalgo": 10,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 0,
      "CountStarr": 8,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/10",
      "Count": 225,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 163,
      "CasesHidalgo": 2,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 8,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/11",
      "Count": 221,
      "Cases": 15,
      "Deaths": 1,
      "Recoveries": 18,
      "CountHidalgo": 163,
      "CasesHidalgo": 5,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 5,
      "CountStarr": 8,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/12",
      "Count": 222,
      "Cases": 17,
      "Deaths": 2,
      "Recoveries": 14,
      "CountHidalgo": 162,
      "CasesHidalgo": 9,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 10,
      "CountStarr": 9,
      "CasesStarr": 1,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/13",
      "Count": 214,
      "Cases": 25,
      "Deaths": 0,
      "Recoveries": 33,
      "CountHidalgo": 164,
      "CasesHidalgo": 6,
      "DeathsHidalgo": 2,
      "RecoveriesHidalgo": 2,
      "CountStarr": 9,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/14",
      "Count": 204,
      "Cases": 13,
      "Deaths": 1,
      "Recoveries": 22,
      "CountHidalgo": 169,
      "CasesHidalgo": 8,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 3,
      "CountStarr": 10,
      "CasesStarr": 1,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/15",
      "Count": 207,
      "Cases": 19,
      "Deaths": 1,
      "Recoveries": 15,
      "CountHidalgo": 166,
      "CasesHidalgo": 8,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 11,
      "CountStarr": 11,
      "CasesStarr": 1,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/16",
      "Count": 194,
      "Cases": 18,
      "Deaths": 0,
      "Recoveries": 31,
      "CountHidalgo": 166,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 11,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 3,
      "CountWillacy": 2,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/17",
      "Count": 194,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 181,
      "CasesHidalgo": 15,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 11,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/18",
      "Count": 206,
      "Cases": 16,
      "Deaths": 2,
      "Recoveries": 2,
      "CountHidalgo": 188,
      "CasesHidalgo": 8,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 1,
      "CountStarr": 13,
      "CasesStarr": 5,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/19",
      "Count": 209,
      "Cases": 14,
      "Deaths": 1,
      "Recoveries": 10,
      "CountHidalgo": 194,
      "CasesHidalgo": 9,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 3,
      "CountStarr": 13,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 2,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/20",
      "Count": 215,
      "Cases": 15,
      "Deaths": 2,
      "Recoveries": 7,
      "CountHidalgo": 197,
      "CasesHidalgo": 10,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 7,
      "CountStarr": 14,
      "CasesStarr": 1,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 3,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/21",
      "Count": 201,
      "Cases": 7,
      "Deaths": 0,
      "Recoveries": 21,
      "CountHidalgo": 191,
      "CasesHidalgo": 5,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 11,
      "CountStarr": 14,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 3,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/22",
      "Count": 199,
      "Cases": 15,
      "Deaths": 0,
      "Recoveries": 17,
      "CountHidalgo": 194,
      "CasesHidalgo": 10,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 7,
      "CountStarr": 15,
      "CasesStarr": 1,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 3,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/23",
      "Count": 192,
      "Cases": 12,
      "Deaths": 1,
      "Recoveries": 18,
      "CountHidalgo": 194,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 15,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 3,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/24",
      "Count": 192,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 209,
      "CasesHidalgo": 15,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 15,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 3,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/25",
      "Count": 192,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 216,
      "CasesHidalgo": 7,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 15,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 3,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/26",
      "Count": 180,
      "Cases": 13,
      "Deaths": 0,
      "Recoveries": 25,
      "CountHidalgo": 198,
      "CasesHidalgo": 12,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 30,
      "CountStarr": 15,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 4,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/27",
      "Count": 176,
      "Cases": 20,
      "Deaths": 0,
      "Recoveries": 24,
      "CountHidalgo": 219,
      "CasesHidalgo": 21,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 18,
      "CasesStarr": 3,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 4,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/28",
      "Count": 170,
      "Cases": 12,
      "Deaths": 2,
      "Recoveries": 16,
      "CountHidalgo": 199,
      "CasesHidalgo": 11,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 31,
      "CountStarr": 18,
      "CasesStarr": 6,
      "DeathsStarr": 0,
      "RecoveriesStarr": 6,
      "CountWillacy": 4,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/29",
      "Count": 166,
      "Cases": 11,
      "Deaths": 1,
      "Recoveries": 14,
      "CountHidalgo": 208,
      "CasesHidalgo": 12,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 3,
      "CountStarr": 18,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 4,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/30",
      "Count": 169,
      "Cases": 10,
      "Deaths": 0,
      "Recoveries": 7,
      "CountHidalgo": 208,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 18,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 4,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/31",
      "Count": 169,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 223,
      "CasesHidalgo": 15,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 18,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 4,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/01",
      "Count": 174,
      "Cases": 12,
      "Deaths": 0,
      "Recoveries": 7,
      "CountHidalgo": 222,
      "CasesHidalgo": 9,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 9,
      "CountStarr": 17,
      "CasesStarr": 4,
      "DeathsStarr": 0,
      "RecoveriesStarr": 5,
      "CountWillacy": 7,
      "CasesWillacy": 3,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/02",
      "Count": 182,
      "Cases": 23,
      "Deaths": 0,
      "Recoveries": 15,
      "CountHidalgo": 232,
      "CasesHidalgo": 26,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 16,
      "CountStarr": 17,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 7,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/03",
      "Count": 182,
      "Cases": 21,
      "Deaths": 3,
      "Recoveries": 18,
      "CountHidalgo": 241,
      "CasesHidalgo": 24,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 15,
      "CountStarr": 17,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 2,
      "CountWillacy": 7,
      "CasesWillacy": 0,
      "DeathsWillacy": 1,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/04",
      "Count": 201,
      "Cases": 21,
      "Deaths": 0,
      "Recoveries": 2,
      "CountHidalgo": 265,
      "CasesHidalgo": 45,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 21,
      "CountStarr": 17,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 7,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/05",
      "Count": 209,
      "Cases": 22,
      "Deaths": 0,
      "Recoveries": 14,
      "CountHidalgo": 268,
      "CasesHidalgo": 22,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 18,
      "CountStarr": 17,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 12,
      "CasesWillacy": 6,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/06",
      "Count": 225,
      "Cases": 29,
      "Deaths": 0,
      "Recoveries": 13,
      "CountHidalgo": 268,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 27,
      "CasesStarr": 13,
      "DeathsStarr": 1,
      "RecoveriesStarr": 0,
      "CountWillacy": 16,
      "CasesWillacy": 4,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/07",
      "Count": 225,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 300,
      "CasesHidalgo": 32,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 32,
      "CasesStarr": 5,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 16,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/08",
      "Count": 235,
      "Cases": 27,
      "Deaths": 0,
      "Recoveries": 17,
      "CountHidalgo": 324,
      "CasesHidalgo": 26,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 2,
      "CountStarr": 36,
      "CasesStarr": 4,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 25,
      "CasesWillacy": 9,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/09",
      "Count": 253,
      "Cases": 32,
      "Deaths": 1,
      "Recoveries": 13,
      "CountHidalgo": 343,
      "CasesHidalgo": 45,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 26,
      "CountStarr": 37,
      "CasesStarr": 8,
      "DeathsStarr": 0,
      "RecoveriesStarr": 7,
      "CountWillacy": 28,
      "CasesWillacy": 3,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/10",
      "Count": 262,
      "Cases": 39,
      "Deaths": 2,
      "Recoveries": 28,
      "CountHidalgo": 366,
      "CasesHidalgo": 38,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 15,
      "CountStarr": 37,
      "CasesStarr": 3,
      "DeathsStarr": 0,
      "RecoveriesStarr": 3,
      "CountWillacy": 29,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/11",
      "Count": 283,
      "Cases": 41,
      "Deaths": 2,
      "Recoveries": 18,
      "CountHidalgo": 406,
      "CasesHidalgo": 70,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 30,
      "CountStarr": 62,
      "CasesStarr": 25,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 34,
      "CasesWillacy": 5,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/12",
      "Count": 293,
      "Cases": 50,
      "Deaths": 0,
      "Recoveries": 40,
      "CountHidalgo": 441,
      "CasesHidalgo": 55,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 20,
      "CountStarr": 64,
      "CasesStarr": 2,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 40,
      "CasesWillacy": 6,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/13",
      "Count": 334,
      "Cases": 57,
      "Deaths": 0,
      "Recoveries": 16,
      "CountHidalgo": 441,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 83,
      "CasesStarr": 19,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 42,
      "CasesWillacy": 2,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/14",
      "Count": 334,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 524,
      "CasesHidalgo": 83,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 93,
      "CasesStarr": 12,
      "DeathsStarr": 0,
      "RecoveriesStarr": 2,
      "CountWillacy": 42,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/15",
      "Count": 353,
      "Cases": 40,
      "Deaths": 0,
      "Recoveries": 21,
      "CountHidalgo": 571,
      "CasesHidalgo": 62,
      "DeathsHidalgo": 3,
      "RecoveriesHidalgo": 12,
      "CountStarr": 119,
      "CasesStarr": 26,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 42,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/16",
      "Count": 384,
      "Cases": 58,
      "Deaths": 1,
      "Recoveries": 26,
      "CountHidalgo": 659,
      "CasesHidalgo": 143,
      "DeathsHidalgo": 2,
      "RecoveriesHidalgo": 53,
      "CountStarr": 132,
      "CasesStarr": 13,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 46,
      "CasesWillacy": 4,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/17",
      "Count": 429,
      "Cases": 65,
      "Deaths": 2,
      "Recoveries": 18,
      "CountHidalgo": 733,
      "CasesHidalgo": 92,
      "DeathsHidalgo": 2,
      "RecoveriesHidalgo": 16,
      "CountStarr": 132,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 52,
      "CasesWillacy": 6,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/18",
      "Count": 466,
      "Cases": 70,
      "Deaths": 1,
      "Recoveries": 32,
      "CountHidalgo": 796,
      "CasesHidalgo": 100,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 36,
      "CountStarr": 181,
      "CasesStarr": 50,
      "DeathsStarr": 1,
      "RecoveriesStarr": 0,
      "CountWillacy": 52,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/19",
      "Count": 527,
      "Cases": 83,
      "Deaths": 0,
      "Recoveries": 22,
      "CountHidalgo": 861,
      "CasesHidalgo": 109,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 43,
      "CountStarr": 181,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 19,
      "CountWillacy": 52,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/20",
      "Count": 554,
      "Cases": 92,
      "Deaths": 0,
      "Recoveries": 65,
      "CountHidalgo": 861,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 268,
      "CasesStarr": 106,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 52,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/21",
      "Count": 554,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 951,
      "CasesHidalgo": 133,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 42,
      "CountStarr": 271,
      "CasesStarr": 3,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 52,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/22",
      "Count": 596,
      "Cases": 101,
      "Deaths": 1,
      "Recoveries": 58,
      "CountHidalgo": 1120,
      "CasesHidalgo": 193,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 23,
      "CountStarr": 323,
      "CasesStarr": 53,
      "DeathsStarr": 1,
      "RecoveriesStarr": 0,
      "CountWillacy": 52,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/23",
      "Count": 646,
      "Cases": 111,
      "Deaths": 1,
      "Recoveries": 60,
      "CountHidalgo": 1344,
      "CasesHidalgo": 248,
      "DeathsHidalgo": 2,
      "RecoveriesHidalgo": 22,
      "CountStarr": 323,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 18,
      "CountWillacy": 73,
      "CasesWillacy": 21,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/24",
      "Count": 696,
      "Cases": 123,
      "Deaths": 2,
      "Recoveries": 71,
      "CountHidalgo": 1666,
      "CasesHidalgo": 373,
      "DeathsHidalgo": 4,
      "RecoveriesHidalgo": 47,
      "CountStarr": 368,
      "CasesStarr": 78,
      "DeathsStarr": 0,
      "RecoveriesStarr": 15,
      "CountWillacy": 105,
      "CasesWillacy": 32,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/25",
      "Count": 702,
      "Cases": 91,
      "Deaths": 0,
      "Recoveries": 85,
      "CountHidalgo": 1796,
      "CasesHidalgo": 210,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 80,
      "CountStarr": 374,
      "CasesStarr": 26,
      "DeathsStarr": 0,
      "RecoveriesStarr": 20,
      "CountWillacy": 105,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/26",
      "Count": 748,
      "Cases": 113,
      "Deaths": 2,
      "Recoveries": 65,
      "CountHidalgo": 1918,
      "CasesHidalgo": 179,
      "DeathsHidalgo": 2,
      "RecoveriesHidalgo": 55,
      "CountStarr": 414,
      "CasesStarr": 58,
      "DeathsStarr": 0,
      "RecoveriesStarr": 18,
      "CountWillacy": 116,
      "CasesWillacy": 11,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/27",
      "Count": 738,
      "Cases": 98,
      "Deaths": 1,
      "Recoveries": 107,
      "CountHidalgo": 1918,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 425,
      "CasesStarr": 11,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 128,
      "CasesWillacy": 12,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/28",
      "Count": 738,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2190,
      "CasesHidalgo": 402,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 129,
      "CountStarr": 471,
      "CasesStarr": 46,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 138,
      "CasesWillacy": 10,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/29",
      "Count": 775,
      "Cases": 113,
      "Deaths": 0,
      "Recoveries": 76,
      "CountHidalgo": 2412,
      "CasesHidalgo": 248,
      "DeathsHidalgo": 3,
      "RecoveriesHidalgo": 23,
      "CountStarr": 489,
      "CasesStarr": 18,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 141,
      "CasesWillacy": 3,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "6/30",
      "Count": 805,
      "Cases": 103,
      "Deaths": 0,
      "Recoveries": 73,
      "CountHidalgo": 2786,
      "CasesHidalgo": 440,
      "DeathsHidalgo": 11,
      "RecoveriesHidalgo": 55,
      "CountStarr": 569,
      "CasesStarr": 102,
      "DeathsStarr": 0,
      "RecoveriesStarr": 22,
      "CountWillacy": 143,
      "CasesWillacy": 2,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/01",
      "Count": 817,
      "Cases": 111,
      "Deaths": 1,
      "Recoveries": 98,
      "CountHidalgo": 3026,
      "CasesHidalgo": 270,
      "DeathsHidalgo": 3,
      "RecoveriesHidalgo": 27,
      "CountStarr": 587,
      "CasesStarr": 24,
      "DeathsStarr": 0,
      "RecoveriesStarr": 6,
      "CountWillacy": 155,
      "CasesWillacy": 12,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/02",
      "Count": 844,
      "Cases": 101,
      "Deaths": 4,
      "Recoveries": 70,
      "CountHidalgo": 3204,
      "CasesHidalgo": 268,
      "DeathsHidalgo": 7,
      "RecoveriesHidalgo": 83,
      "CountStarr": 668,
      "CasesStarr": 81,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 155,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/03",
      "Count": 833,
      "Cases": 81,
      "Deaths": 3,
      "Recoveries": 89,
      "CountHidalgo": 3142,
      "CasesHidalgo": 278,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 339,
      "CountStarr": 703,
      "CasesStarr": 40,
      "DeathsStarr": 5,
      "RecoveriesStarr": 0,
      "CountWillacy": 194,
      "CasesWillacy": 39,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/04",
      "Count": 708,
      "Cases": 87,
      "Deaths": 4,
      "Recoveries": 208,
      "CountHidalgo": 3142,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 0,
      "CountStarr": 703,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 192,
      "CasesWillacy": 18,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 20
  },
  {
      "Date": "7/05",
      "Count": 708,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 3452,
      "CasesHidalgo": 547,
      "DeathsHidalgo": 1,
      "RecoveriesHidalgo": 235,
      "CountStarr": 703,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 200,
      "CasesWillacy": 8,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/06",
      "Count": 739,
      "Cases": 101,
      "Deaths": 1,
      "Recoveries": 69,
      "CountHidalgo": 2975,
      "CasesHidalgo": 102,
      "DeathsHidalgo": 16,
      "RecoveriesHidalgo": 563,
      "CountStarr": 707,
      "CasesStarr": 4,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 200,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/07",
      "Count": 747,
      "Cases": 117,
      "Deaths": 2,
      "Recoveries": 107,
      "CountHidalgo": 2955,
      "CasesHidalgo": 335,
      "DeathsHidalgo": 15,
      "RecoveriesHidalgo": 340,
      "CountStarr": 666,
      "CasesStarr": 69,
      "DeathsStarr": 0,
      "RecoveriesStarr": 110,
      "CountWillacy": 209,
      "CasesWillacy": 9,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/08",
      "Count": 764,
      "Cases": 123,
      "Deaths": 1,
      "Recoveries": 105,
      "CountHidalgo": 3160,
      "CasesHidalgo": 278,
      "DeathsHidalgo": 13,
      "RecoveriesHidalgo": 60,
      "CountStarr": 735,
      "CasesStarr": 69,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 210,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/09",
      "Count": 834,
      "Cases": 126,
      "Deaths": 0,
      "Recoveries": 56,
      "CountHidalgo": 4375,
      "CasesHidalgo": 1274,
      "DeathsHidalgo": 20,
      "RecoveriesHidalgo": 39,
      "CountStarr": 735,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 218,
      "CasesWillacy": 8,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/10",
      "Count": 1015,
      "Cases": 250,
      "Deaths": 6,
      "Recoveries": 63,
      "CountHidalgo": 4696,
      "CasesHidalgo": 393,
      "DeathsHidalgo": 19,
      "RecoveriesHidalgo": 53,
      "CountStarr": 762,
      "CasesStarr": 27,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 223,
      "CasesWillacy": 5,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/11",
      "Count": 1214,
      "Cases": 345,
      "Deaths": 2,
      "Recoveries": 144,
      "CountHidalgo": 4696,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 751,
      "CasesStarr": 25,
      "DeathsStarr": 0,
      "RecoveriesStarr": 36,
      "CountWillacy": 234,
      "CasesWillacy": 11,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/12",
      "Count": 1214,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 4874,
      "CasesHidalgo": 313,
      "DeathsHidalgo": 8,
      "RecoveriesHidalgo": 127,
      "CountStarr": 796,
      "CasesStarr": 45,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 292,
      "CasesWillacy": 58,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/13",
      "Count": 1491,
      "Cases": 319,
      "Deaths": 0,
      "Recoveries": 42,
      "CountHidalgo": 4666,
      "CasesHidalgo": 157,
      "DeathsHidalgo": 2,
      "RecoveriesHidalgo": 363,
      "CountStarr": 807,
      "CasesStarr": 11,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 313,
      "CasesWillacy": 21,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/14",
      "Count": 1869,
      "Cases": 415,
      "Deaths": 0,
      "Recoveries": 37,
      "CountHidalgo": 5031,
      "CasesHidalgo": 396,
      "DeathsHidalgo": 31,
      "RecoveriesHidalgo": 0,
      "CountStarr": 862,
      "CasesStarr": 55,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 323,
      "CasesWillacy": 10,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/15",
      "Count": 2141,
      "Cases": 315,
      "Deaths": 0,
      "Recoveries": 43,
      "CountHidalgo": 4871,
      "CasesHidalgo": 651,
      "DeathsHidalgo": 35,
      "RecoveriesHidalgo": 776,
      "CountStarr": 909,
      "CasesStarr": 47,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 377,
      "CasesWillacy": 54,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/16",
      "Count": 2413,
      "Cases": 335,
      "Deaths": 10,
      "Recoveries": 53,
      "CountHidalgo": 5861,
      "CasesHidalgo": 1248,
      "DeathsHidalgo": 22,
      "RecoveriesHidalgo": 236,
      "CountStarr": 968,
      "CasesStarr": 147,
      "DeathsStarr": 0,
      "RecoveriesStarr": 88,
      "CountWillacy": 407,
      "CasesWillacy": 30,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/17",
      "Count": 2699,
      "Cases": 325,
      "Deaths": 2,
      "Recoveries": 37,
      "CountHidalgo": 5780,
      "CasesHidalgo": 451,
      "DeathsHidalgo": 27,
      "RecoveriesHidalgo": 505,
      "CountStarr": 898,
      "CasesStarr": 26,
      "DeathsStarr": 0,
      "RecoveriesStarr": 96,
      "CountWillacy": 429,
      "CasesWillacy": 22,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/18",
      "Count": 2949,
      "Cases": 326,
      "Deaths": 5,
      "Recoveries": 71,
      "CountHidalgo": 5780,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 898,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 438,
      "CasesWillacy": 9,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/19",
      "Count": 2949,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 6821,
      "CasesHidalgo": 1320,
      "DeathsHidalgo": 17,
      "RecoveriesHidalgo": 262,
      "CountStarr": 917,
      "CasesStarr": 19,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 440,
      "CasesWillacy": 2,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/20",
      "Count": 3128,
      "Cases": 322,
      "Deaths": 15,
      "Recoveries": 128,
      "CountHidalgo": 7203,
      "CasesHidalgo": 524,
      "DeathsHidalgo": 34,
      "RecoveriesHidalgo": 108,
      "CountStarr": 938,
      "CasesStarr": 21,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 441,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/21",
      "Count": 3323,
      "Cases": 307,
      "Deaths": 15,
      "Recoveries": 97,
      "CountHidalgo": 7286,
      "CasesHidalgo": 339,
      "DeathsHidalgo": 49,
      "RecoveriesHidalgo": 207,
      "CountStarr": 1083,
      "CasesStarr": 145,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 464,
      "CasesWillacy": 23,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/22",
      "Count": 3618,
      "Cases": 334,
      "Deaths": 11,
      "Recoveries": 28,
      "CountHidalgo": 7675,
      "CasesHidalgo": 650,
      "DeathsHidalgo": 33,
      "RecoveriesHidalgo": 228,
      "CountStarr": 1055,
      "CasesStarr": 44,
      "DeathsStarr": 9,
      "RecoveriesStarr": 63,
      "CountWillacy": 487,
      "CasesWillacy": 23,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/23",
      "Count": 3905,
      "Cases": 308,
      "Deaths": 16,
      "Recoveries": 5,
      "CountHidalgo": 7250,
      "CasesHidalgo": 813,
      "DeathsHidalgo": 33,
      "RecoveriesHidalgo": 1205,
      "CountStarr": 1055,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 502,
      "CasesWillacy": 15,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/24",
      "Count": 4175,
      "Cases": 324,
      "Deaths": 16,
      "Recoveries": 38,
      "CountHidalgo": 7423,
      "CasesHidalgo": 564,
      "DeathsHidalgo": 23,
      "RecoveriesHidalgo": 368,
      "CountStarr": 1055,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 502,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/25",
      "Count": 4436,
      "Cases": 341,
      "Deaths": 8,
      "Recoveries": 72,
      "CountHidalgo": 7423,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 1055,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 524,
      "CasesWillacy": 22,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/26",
      "Count": 4436,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 7423,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 1237,
      "CasesStarr": 182,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 534,
      "CasesWillacy": 10,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/27",
      "Count": 4757,
      "Cases": 374,
      "Deaths": 6,
      "Recoveries": 47,
      "CountHidalgo": 7548,
      "CasesHidalgo": 596,
      "DeathsHidalgo": 11,
      "RecoveriesHidalgo": 460,
      "CountStarr": 1300,
      "CasesStarr": 68,
      "DeathsStarr": 5,
      "RecoveriesStarr": 0,
      "CountWillacy": 537,
      "CasesWillacy": 3,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/28",
      "Count": 5275,
      "Cases": 576,
      "Deaths": 9,
      "Recoveries": 49,
      "CountHidalgo": 7450,
      "CasesHidalgo": 339,
      "DeathsHidalgo": 64,
      "RecoveriesHidalgo": 373,
      "CountStarr": 1346,
      "CasesStarr": 46,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 539,
      "CasesWillacy": 2,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/29",
      "Count": 5856,
      "Cases": 647,
      "Deaths": 8,
      "Recoveries": 58,
      "CountHidalgo": 7106,
      "CasesHidalgo": 287,
      "DeathsHidalgo": 45,
      "RecoveriesHidalgo": 586,
      "CountStarr": 1346,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 545,
      "CasesWillacy": 6,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/30",
      "Count": 6228,
      "Cases": 731,
      "Deaths": 6,
      "Recoveries": 353,
      "CountHidalgo": 6131,
      "CasesHidalgo": 271,
      "DeathsHidalgo": 40,
      "RecoveriesHidalgo": 1206,
      "CountStarr": 1233,
      "CasesStarr": 28,
      "DeathsStarr": 0,
      "RecoveriesStarr": 141,
      "CountWillacy": 548,
      "CasesWillacy": 3,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "7/31",
      "Count": 6699,
      "Cases": 816,
      "Deaths": 10,
      "Recoveries": 335,
      "CountHidalgo": 6041,
      "CasesHidalgo": 360,
      "DeathsHidalgo": 28,
      "RecoveriesHidalgo": 422,
      "CountStarr": 1275,
      "CasesStarr": 42,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 553,
      "CasesWillacy": 5,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/01",
      "Count": 7031,
      "Cases": 1106,
      "Deaths": 10,
      "Recoveries": 764,
      "CountHidalgo": 6041,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 1306,
      "CasesStarr": 87,
      "DeathsStarr": 0,
      "RecoveriesStarr": 56,
      "CountWillacy": 553,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/02",
      "Count": 8227,
      "Cases": 1439,
      "Deaths": 21,
      "Recoveries": 222,
      "CountHidalgo": 4649,
      "CasesHidalgo": 347,
      "DeathsHidalgo": 12,
      "RecoveriesHidalgo": 1727,
      "CountStarr": 1350,
      "CasesStarr": 47,
      "DeathsStarr": 0,
      "RecoveriesStarr": 3,
      "CountWillacy": 553,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/03",
      "Count": 9276,
      "Cases": 1265,
      "Deaths": 11,
      "Recoveries": 205,
      "CountHidalgo": 4986,
      "CasesHidalgo": 398,
      "DeathsHidalgo": 26,
      "RecoveriesHidalgo": 35,
      "CountStarr": 1439,
      "CasesStarr": 89,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 561,
      "CasesWillacy": 8,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/04",
      "Count": 9383,
      "Cases": 337,
      "Deaths": 45,
      "Recoveries": 185,
      "CountHidalgo": 5176,
      "CasesHidalgo": 498,
      "DeathsHidalgo": 45,
      "RecoveriesHidalgo": 263,
      "CountStarr": 1495,
      "CasesStarr": 56,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 581,
      "CasesWillacy": 20,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/05",
      "Count": 9605,
      "Cases": 446,
      "Deaths": 21,
      "Recoveries": 203,
      "CountHidalgo": 4991,
      "CasesHidalgo": 450,
      "DeathsHidalgo": 16,
      "RecoveriesHidalgo": 619,
      "CountStarr": 1613,
      "CasesStarr": 118,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 611,
      "CasesWillacy": 30,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/06",
      "Count": 9519,
      "Cases": 301,
      "Deaths": 17,
      "Recoveries": 370,
      "CountHidalgo": 4637,
      "CasesHidalgo": 404,
      "DeathsHidalgo": 22,
      "RecoveriesHidalgo": 736,
      "CountStarr": 1638,
      "CasesStarr": 25,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 636,
      "CasesWillacy": 25,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/07",
      "Count": 9525,
      "Cases": 425,
      "Deaths": 17,
      "Recoveries": 402,
      "CountHidalgo": 4508,
      "CasesHidalgo": 431,
      "DeathsHidalgo": 25,
      "RecoveriesHidalgo": 535,
      "CountStarr": 1711,
      "CasesStarr": 73,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 649,
      "CasesWillacy": 13,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/08",
      "Count": 8915,
      "Cases": 300,
      "Deaths": 9,
      "Recoveries": 901,
      "CountHidalgo": 4508,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 1607,
      "CasesStarr": 10,
      "DeathsStarr": 0,
      "RecoveriesStarr": 114,
      "CountWillacy": 658,
      "CasesWillacy": 9,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/09",
      "Count": 8915,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 4124,
      "CasesHidalgo": 207,
      "DeathsHidalgo": 17,
      "RecoveriesHidalgo": 574,
      "CountStarr": 1620,
      "CasesStarr": 13,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 660,
      "CasesWillacy": 2,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/10",
      "Count": 8817,
      "Cases": 316,
      "Deaths": 11,
      "Recoveries": 403,
      "CountHidalgo": 4503,
      "CasesHidalgo": 407,
      "DeathsHidalgo": 22,
      "RecoveriesHidalgo": 6,
      "CountStarr": 1620,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 666,
      "CasesWillacy": 6,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/11",
      "Count": 8258,
      "Cases": 163,
      "Deaths": 10,
      "Recoveries": 712,
      "CountHidalgo": 4493,
      "CasesHidalgo": 283,
      "DeathsHidalgo": 20,
      "RecoveriesHidalgo": 273,
      "CountStarr": 1620,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 677,
      "CasesWillacy": 11,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/12",
      "Count": 7804,
      "Cases": 247,
      "Deaths": 9,
      "Recoveries": 692,
      "CountHidalgo": 4564,
      "CasesHidalgo": 336,
      "DeathsHidalgo": 32,
      "RecoveriesHidalgo": 233,
      "CountStarr": 1693,
      "CasesStarr": 73,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 687,
      "CasesWillacy": 10,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/13",
      "Count": 7386,
      "Cases": 302,
      "Deaths": 10,
      "Recoveries": 710,
      "CountHidalgo": 4814,
      "CasesHidalgo": 508,
      "DeathsHidalgo": 30,
      "RecoveriesHidalgo": 228,
      "CountStarr": 1810,
      "CasesStarr": 117,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 701,
      "CasesWillacy": 14,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/14",
      "Count": 6736,
      "Cases": 198,
      "Deaths": 11,
      "Recoveries": 837,
      "CountHidalgo": 5012,
      "CasesHidalgo": 531,
      "DeathsHidalgo": 20,
      "RecoveriesHidalgo": 313,
      "CountStarr": 1664,
      "CasesStarr": 38,
      "DeathsStarr": 0,
      "RecoveriesStarr": 184,
      "CountWillacy": 718,
      "CasesWillacy": 17,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/15",
      "Count": 6576,
      "Cases": 189,
      "Deaths": 12,
      "Recoveries": 337,
      "CountHidalgo": 5012,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 1673,
      "CasesStarr": 9,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 734,
      "CasesWillacy": 16,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/16",
      "Count": 6576,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 4960,
      "CasesHidalgo": 207,
      "DeathsHidalgo": 24,
      "RecoveriesHidalgo": 235,
      "CountStarr": 1693,
      "CasesStarr": 20,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 741,
      "CasesWillacy": 7,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/17",
      "Count": 6416,
      "Cases": 165,
      "Deaths": 10,
      "Recoveries": 315,
      "CountHidalgo": 4849,
      "CasesHidalgo": 285,
      "DeathsHidalgo": 19,
      "RecoveriesHidalgo": 377,
      "CountStarr": 1691,
      "CasesStarr": 28,
      "DeathsStarr": 0,
      "RecoveriesStarr": 30,
      "CountWillacy": 741,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/18",
      "Count": 6307,
      "Cases": 168,
      "Deaths": 21,
      "Recoveries": 256,
      "CountHidalgo": 4902,
      "CasesHidalgo": 528,
      "DeathsHidalgo": 25,
      "RecoveriesHidalgo": 450,
      "CountStarr": 1668,
      "CasesStarr": 17,
      "DeathsStarr": 0,
      "RecoveriesStarr": 40,
      "CountWillacy": 741,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/19",
      "Count": 6229,
      "Cases": 221,
      "Deaths": 17,
      "Recoveries": 282,
      "CountHidalgo": 4787,
      "CasesHidalgo": 290,
      "DeathsHidalgo": 20,
      "RecoveriesHidalgo": 385,
      "CountStarr": 1674,
      "CasesStarr": 6,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 744,
      "CasesWillacy": 3,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/20",
      "Count": 6023,
      "Cases": 215,
      "Deaths": 11,
      "Recoveries": 410,
      "CountHidalgo": 4756,
      "CasesHidalgo": 397,
      "DeathsHidalgo": 29,
      "RecoveriesHidalgo": 399,
      "CountStarr": 1680,
      "CasesStarr": 6,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 756,
      "CasesWillacy": 12,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/21",
      "Count": 5934,
      "Cases": 227,
      "Deaths": 6,
      "Recoveries": 310,
      "CountHidalgo": 4427,
      "CasesHidalgo": 480,
      "DeathsHidalgo": 23,
      "RecoveriesHidalgo": 786,
      "CountStarr": 1633,
      "CasesStarr": 31,
      "DeathsStarr": 78,
      "RecoveriesStarr": 0,
      "CountWillacy": 769,
      "CasesWillacy": 13,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/22",
      "Count": 5934,
      "Cases": 0,
      "Deaths": 15,
      "Recoveries": 223,
      "CountHidalgo": 4427,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 1650,
      "CasesStarr": 22,
      "DeathsStarr": 5,
      "RecoveriesStarr": 0,
      "CountWillacy": 772,
      "CasesWillacy": 3,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/23",
      "Count": 5934,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2421,
      "CasesHidalgo": 501,
      "DeathsHidalgo": 20,
      "RecoveriesHidalgo": 2487,
      "CountStarr": 1674,
      "CasesStarr": 24,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 777,
      "CasesWillacy": 5,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/24",
      "Count": 6012,
      "Cases": 638,
      "Deaths": 16,
      "Recoveries": 306,
      "CountHidalgo": 2493,
      "CasesHidalgo": 675,
      "DeathsHidalgo": 10,
      "RecoveriesHidalgo": 593,
      "CountStarr": 1694,
      "CasesStarr": 32,
      "DeathsStarr": 12,
      "RecoveriesStarr": 0,
      "CountWillacy": 784,
      "CasesWillacy": 7,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/25",
      "Count": 6141,
      "Cases": 473,
      "Deaths": 10,
      "Recoveries": 334,
      "CountHidalgo": 2565,
      "CasesHidalgo": 782,
      "DeathsHidalgo": 20,
      "RecoveriesHidalgo": 690,
      "CountStarr": 1699,
      "CasesStarr": 5,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 785,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/26",
      "Count": 6077,
      "Cases": 282,
      "Deaths": 12,
      "Recoveries": 334,
      "CountHidalgo": 2646,
      "CasesHidalgo": 655,
      "DeathsHidalgo": 9,
      "RecoveriesHidalgo": 565,
      "CountStarr": 1699,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 789,
      "CasesWillacy": 4,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/27",
      "Count": 5754,
      "Cases": 122,
      "Deaths": 8,
      "Recoveries": 437,
      "CountHidalgo": 2582,
      "CasesHidalgo": 471,
      "DeathsHidalgo": 15,
      "RecoveriesHidalgo": 520,
      "CountStarr": 1699,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 789,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/28",
      "Count": 5675,
      "Cases": 114,
      "Deaths": 10,
      "Recoveries": 183,
      "CountHidalgo": 2596,
      "CasesHidalgo": 175,
      "DeathsHidalgo": 11,
      "RecoveriesHidalgo": 150,
      "CountStarr": 1610,
      "CasesStarr": 24,
      "DeathsStarr": 0,
      "RecoveriesStarr": 113,
      "CountWillacy": 789,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/29",
      "Count": 5559,
      "Cases": 106,
      "Deaths": 10,
      "Recoveries": 212,
      "CountHidalgo": 2610,
      "CasesHidalgo": 175,
      "DeathsHidalgo": 11,
      "RecoveriesHidalgo": 150,
      "CountStarr": 1610,
      "CasesStarr": 0,
      "DeathsStarr": 12,
      "RecoveriesStarr": 0,
      "CountWillacy": 789,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/30",
      "Count": 5559,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2681,
      "CasesHidalgo": 156,
      "DeathsHidalgo": 6,
      "RecoveriesHidalgo": 79,
      "CountStarr": 1610,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 789,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "8/31",
      "Count": 5212,
      "Cases": 81,
      "Deaths": 20,
      "Recoveries": 408,
      "CountHidalgo": 2674,
      "CasesHidalgo": 130,
      "DeathsHidalgo": 9,
      "RecoveriesHidalgo": 128,
      "CountStarr": 1610,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 819,
      "CasesWillacy": 30,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/01",
      "Count": 4958,
      "Cases": 171,
      "Deaths": 10,
      "Recoveries": 415,
      "CountHidalgo": 2543,
      "CasesHidalgo": 131,
      "DeathsHidalgo": 14,
      "RecoveriesHidalgo": 248,
      "CountStarr": 1597,
      "CasesStarr": 4,
      "DeathsStarr": 5,
      "RecoveriesStarr": 0,
      "CountWillacy": 821,
      "CasesWillacy": 2,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/02",
      "Count": 4669,
      "Cases": 131,
      "Deaths": 15,
      "Recoveries": 405,
      "CountHidalgo": 2628,
      "CasesHidalgo": 239,
      "DeathsHidalgo": 23,
      "RecoveriesHidalgo": 131,
      "CountStarr": 1597,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 821,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/03",
      "Count": 4387,
      "Cases": 118,
      "Deaths": 13,
      "Recoveries": 387,
      "CountHidalgo": 2829,
      "CasesHidalgo": 270,
      "DeathsHidalgo": 25,
      "RecoveriesHidalgo": 44,
      "CountStarr": 1597,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 821,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/04",
      "Count": 3981,
      "Cases": 103,
      "Deaths": 68,
      "Recoveries": 441,
      "CountHidalgo": 2931,
      "CasesHidalgo": 191,
      "DeathsHidalgo": 17,
      "RecoveriesHidalgo": 72,
      "CountStarr": 1620,
      "CasesStarr": 23,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 803,
      "CasesWillacy": 23,
      "DeathsWillacy": 41,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/05",
      "Count": 3604,
      "Cases": 91,
      "Deaths": 57,
      "Recoveries": 411,
      "CountHidalgo": 2931,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 1620,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 825,
      "CasesWillacy": 22,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/06",
      "Count": 3604,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2859,
      "CasesHidalgo": 222,
      "DeathsHidalgo": 22,
      "RecoveriesHidalgo": 272,
      "CountStarr": 1620,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 825,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/07",
      "Count": 3827,
      "Cases": 223,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2233,
      "CasesHidalgo": 141,
      "DeathsHidalgo": 23,
      "RecoveriesHidalgo": 744,
      "CountStarr": 1620,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 825,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/08",
      "Count": 3437,
      "Cases": 148,
      "Deaths": 19,
      "Recoveries": 519,
      "CountHidalgo": 2163,
      "CasesHidalgo": 161,
      "DeathsHidalgo": 16,
      "RecoveriesHidalgo": 215,
      "CountStarr": 1620,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 833,
      "CasesWillacy": 8,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/09",
      "Count": 3143,
      "Cases": 81,
      "Deaths": 20,
      "Recoveries": 355,
      "CountHidalgo": 2135,
      "CasesHidalgo": 189,
      "DeathsHidalgo": 23,
      "RecoveriesHidalgo": 194,
      "CountStarr": 547,
      "CasesStarr": 534,
      "DeathsStarr": 11,
      "RecoveriesStarr": 1596,
      "CountWillacy": 833,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/10",
      "Count": 2938,
      "Cases": 60,
      "Deaths": 13,
      "Recoveries": 252,
      "CountHidalgo": 2131,
      "CasesHidalgo": 127,
      "DeathsHidalgo": 21,
      "RecoveriesHidalgo": 110,
      "CountStarr": 547,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 835,
      "CasesWillacy": 2,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/11",
      "Count": 2731,
      "Cases": 40,
      "Deaths": 11,
      "Recoveries": 236,
      "CountHidalgo": 2094,
      "CasesHidalgo": 126,
      "DeathsHidalgo": 26,
      "RecoveriesHidalgo": 137,
      "CountStarr": 547,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 835,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/12",
      "Count": 2731,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2094,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 547,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 835,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/13",
      "Count": 2731,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2082,
      "CasesHidalgo": 109,
      "DeathsHidalgo": 27,
      "RecoveriesHidalgo": 94,
      "CountStarr": 547,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 835,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/14",
      "Count": 2664,
      "Cases": 169,
      "Deaths": 13,
      "Recoveries": 223,
      "CountHidalgo": 2045,
      "CasesHidalgo": 73,
      "DeathsHidalgo": 22,
      "RecoveriesHidalgo": 88,
      "CountStarr": 455,
      "CasesStarr": 35,
      "DeathsStarr": 1,
      "RecoveriesStarr": 126,
      "CountWillacy": 975,
      "CasesWillacy": 140,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/15",
      "Count": 2410,
      "Cases": 70,
      "Deaths": 8,
      "Recoveries": 316,
      "CountHidalgo": 2070,
      "CasesHidalgo": 202,
      "DeathsHidalgo": 19,
      "RecoveriesHidalgo": 158,
      "CountStarr": 564,
      "CasesStarr": 109,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 975,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/16",
      "Count": 2248,
      "Cases": 54,
      "Deaths": 10,
      "Recoveries": 206,
      "CountHidalgo": 2112,
      "CasesHidalgo": 325,
      "DeathsHidalgo": 20,
      "RecoveriesHidalgo": 263,
      "CountStarr": 564,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 1088,
      "CasesWillacy": 113,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/17",
      "Count": 2161,
      "Cases": 63,
      "Deaths": 9,
      "Recoveries": 141,
      "CountHidalgo": 2171,
      "CasesHidalgo": 329,
      "DeathsHidalgo": 18,
      "RecoveriesHidalgo": 252,
      "CountStarr": 569,
      "CasesStarr": 5,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 1089,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/18",
      "Count": 2093,
      "Cases": 66,
      "Deaths": 8,
      "Recoveries": 126,
      "CountHidalgo": 2351,
      "CasesHidalgo": 202,
      "DeathsHidalgo": 22,
      "RecoveriesHidalgo": 0,
      "CountStarr": 567,
      "CasesStarr": 6,
      "DeathsStarr": 8,
      "RecoveriesStarr": 0,
      "CountWillacy": 1089,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/19",
      "Count": 2093,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2351,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 567,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 1089,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/20",
      "Count": 2093,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2351,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 567,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 1089,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/21",
      "Count": 2076,
      "Cases": 94,
      "Deaths": 8,
      "Recoveries": 103,
      "CountHidalgo": 2370,
      "CasesHidalgo": 166,
      "DeathsHidalgo": 30,
      "RecoveriesHidalgo": 117,
      "CountStarr": 582,
      "CasesStarr": 15,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 10,
      "CasesWillacy": 7,
      "DeathsWillacy": 5,
      "RecoveriesWillacy": 1081
  },
  {
      "Date": "9/22",
      "Count": 2019,
      "Cases": 45,
      "Deaths": 9,
      "Recoveries": 93,
      "CountHidalgo": 2364,
      "CasesHidalgo": 255,
      "DeathsHidalgo": 23,
      "RecoveriesHidalgo": 238,
      "CountStarr": 582,
      "CasesStarr": 5,
      "DeathsStarr": 5,
      "RecoveriesStarr": 0,
      "CountWillacy": 11,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/23",
      "Count": 1980,
      "Cases": 42,
      "Deaths": 9,
      "Recoveries": 72,
      "CountHidalgo": 2343,
      "CasesHidalgo": 164,
      "DeathsHidalgo": 25,
      "RecoveriesHidalgo": 160,
      "CountStarr": 590,
      "CasesStarr": 8,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 12,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/24",
      "Count": 1942,
      "Cases": 47,
      "Deaths": 8,
      "Recoveries": 77,
      "CountHidalgo": 2323,
      "CasesHidalgo": 209,
      "DeathsHidalgo": 23,
      "RecoveriesHidalgo": 206,
      "CountStarr": 590,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 12,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/25",
      "Count": 1914,
      "Cases": 65,
      "Deaths": 10,
      "Recoveries": 83,
      "CountHidalgo": 2336,
      "CasesHidalgo": 191,
      "DeathsHidalgo": 19,
      "RecoveriesHidalgo": 159,
      "CountStarr": 602,
      "CasesStarr": 12,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 14,
      "CasesWillacy": 2,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/26",
      "Count": 1914,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2336,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 602,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 14,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/27",
      "Count": 1914,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2336,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 602,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 14,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/28",
      "Count": 1872,
      "Cases": 83,
      "Deaths": 13,
      "Recoveries": 112,
      "CountHidalgo": 2302,
      "CasesHidalgo": 115,
      "DeathsHidalgo": 31,
      "RecoveriesHidalgo": 118,
      "CountStarr": 602,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 14,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/29",
      "Count": 1831,
      "Cases": 44,
      "Deaths": 9,
      "Recoveries": 76,
      "CountHidalgo": 2319,
      "CasesHidalgo": 158,
      "DeathsHidalgo": 20,
      "RecoveriesHidalgo": 121,
      "CountStarr": 409,
      "CasesStarr": 4,
      "DeathsStarr": 5,
      "RecoveriesStarr": 192,
      "CountWillacy": 15,
      "CasesWillacy": 1,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "9/30",
      "Count": 1819,
      "Cases": 50,
      "Deaths": 9,
      "Recoveries": 53,
      "CountHidalgo": 2295,
      "CasesHidalgo": 150,
      "DeathsHidalgo": 17,
      "RecoveriesHidalgo": 157,
      "CountStarr": 424,
      "CasesStarr": 18,
      "DeathsStarr": 3,
      "RecoveriesStarr": 0,
      "CountWillacy": 15,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/01",
      "Count": 1754,
      "Cases": 40,
      "Deaths": 8,
      "Recoveries": 97,
      "CountHidalgo": 2398,
      "CasesHidalgo": 214,
      "DeathsHidalgo": 20,
      "RecoveriesHidalgo": 91,
      "CountStarr": 437,
      "CasesStarr": 15,
      "DeathsStarr": 2,
      "RecoveriesStarr": 0,
      "CountWillacy": 15,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/02",
      "Count": 1630,
      "Cases": 28,
      "Deaths": 69,
      "Recoveries": 83,
      "CountHidalgo": 2459,
      "CasesHidalgo": 208,
      "DeathsHidalgo": 22,
      "RecoveriesHidalgo": 125,
      "CountStarr": 441,
      "CasesStarr": 4,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 18,
      "CasesWillacy": 3,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/03",
      "Count": 1630,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2459,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 441,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 18,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/04",
      "Count": 1630,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2459,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 441,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 18,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/05",
      "Count": 1660,
      "Cases": 82,
      "Deaths": 5,
      "Recoveries": 47,
      "CountHidalgo": 2493,
      "CasesHidalgo": 224,
      "DeathsHidalgo": 26,
      "RecoveriesHidalgo": 164,
      "CountStarr": 441,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 18,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/06",
      "Count": 1645,
      "Cases": 54,
      "Deaths": 4,
      "Recoveries": 65,
      "CountHidalgo": 2512,
      "CasesHidalgo": 183,
      "DeathsHidalgo": 18,
      "RecoveriesHidalgo": 146,
      "CountStarr": 321,
      "CasesStarr": 9,
      "DeathsStarr": 2,
      "RecoveriesStarr": 127,
      "CountWillacy": 27,
      "CasesWillacy": 9,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/07",
      "Count": 1560,
      "Cases": 43,
      "Deaths": 3,
      "Recoveries": 125,
      "CountHidalgo": 2567,
      "CasesHidalgo": 205,
      "DeathsHidalgo": 21,
      "RecoveriesHidalgo": 129,
      "CountStarr": 324,
      "CasesStarr": 3,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 27,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/08",
      "Count": 1496,
      "Cases": 47,
      "Deaths": 5,
      "Recoveries": 106,
      "CountHidalgo": 2573,
      "CasesHidalgo": 130,
      "DeathsHidalgo": 19,
      "RecoveriesHidalgo": 105,
      "CountStarr": 324,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 27,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/09",
      "Count": 1525,
      "Cases": 138,
      "Deaths": 2,
      "Recoveries": 107,
      "CountHidalgo": 2510,
      "CasesHidalgo": 204,
      "DeathsHidalgo": 8,
      "RecoveriesHidalgo": 259,
      "CountStarr": 324,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 27,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/10",
      "Count": 1525,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2510,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 324,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 27,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/11",
      "Count": 1525,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2510,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 324,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 27,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/12",
      "Count": 1563,
      "Cases": 180,
      "Deaths": 3,
      "Recoveries": 139,
      "CountHidalgo": 2366,
      "CasesHidalgo": 143,
      "DeathsHidalgo": 12,
      "RecoveriesHidalgo": 275,
      "CountStarr": 324,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 65,
      "CasesWillacy": 38,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/13",
      "Count": 1475,
      "Cases": 67,
      "Deaths": 5,
      "Recoveries": 150,
      "CountHidalgo": 2363,
      "CasesHidalgo": 119,
      "DeathsHidalgo": 15,
      "RecoveriesHidalgo": 107,
      "CountStarr": 103,
      "CasesStarr": 8,
      "DeathsStarr": 7,
      "RecoveriesStarr": 222,
      "CountWillacy": 76,
      "CasesWillacy": 11,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/14",
      "Count": 1373,
      "Cases": 53,
      "Deaths": 6,
      "Recoveries": 149,
      "CountHidalgo": 2440,
      "CasesHidalgo": 174,
      "DeathsHidalgo": 16,
      "RecoveriesHidalgo": 81,
      "CountStarr": 109,
      "CasesStarr": 6,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 76,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/15",
      "Count": 1167,
      "Cases": 100,
      "Deaths": 10,
      "Recoveries": 296,
      "CountHidalgo": 2538,
      "CasesHidalgo": 492,
      "DeathsHidalgo": 16,
      "RecoveriesHidalgo": 378,
      "CountStarr": 109,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 76,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/16",
      "Count": 1147,
      "Cases": 47,
      "Deaths": 3,
      "Recoveries": 64,
      "CountHidalgo": 2532,
      "CasesHidalgo": 145,
      "DeathsHidalgo": 3,
      "RecoveriesHidalgo": 148,
      "CountStarr": 109,
      "CasesStarr": 0,
      "DeathsStarr": 1,
      "RecoveriesStarr": 0,
      "CountWillacy": 76,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/17",
      "Count": 1147,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2532,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 109,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 76,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/18",
      "Count": 1147,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2532,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 109,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 76,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/19",
      "Count": 1183,
      "Cases": 109,
      "Deaths": 2,
      "Recoveries": 71,
      "CountHidalgo": 2532,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 109,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 76,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/20",
      "Count": 1152,
      "Cases": 53,
      "Deaths": 2,
      "Recoveries": 82,
      "CountHidalgo": 2613,
      "CasesHidalgo": 164,
      "DeathsHidalgo": 4,
      "RecoveriesHidalgo": 79,
      "CountStarr": 109,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 76,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/21",
      "Count": 1088,
      "Cases": 35,
      "Deaths": 2,
      "Recoveries": 97,
      "CountHidalgo": 2588,
      "CasesHidalgo": 172,
      "DeathsHidalgo": 7,
      "RecoveriesHidalgo": 190,
      "CountStarr": 109,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 76,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/22",
      "Count": 1064,
      "Cases": 31,
      "Deaths": 3,
      "Recoveries": 52,
      "CountHidalgo": 2590,
      "CasesHidalgo": 284,
      "DeathsHidalgo": 8,
      "RecoveriesHidalgo": 274,
      "CountStarr": 112,
      "CasesStarr": 4,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 16,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/23",
      "Count": 1057,
      "Cases": 39,
      "Deaths": 3,
      "Recoveries": 43,
      "CountHidalgo": 2701,
      "CasesHidalgo": 171,
      "DeathsHidalgo": 5,
      "RecoveriesHidalgo": 55,
      "CountStarr": 112,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/24",
      "Count": 1057,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2701,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 112,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/25",
      "Count": 1057,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2701,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 112,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/26",
      "Count": 1068,
      "Cases": 109,
      "Deaths": 1,
      "Recoveries": 97,
      "CountHidalgo": 2140,
      "CasesHidalgo": 130,
      "DeathsHidalgo": 5,
      "RecoveriesHidalgo": 686,
      "CountStarr": 112,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/27",
      "Count": 1052,
      "Cases": 65,
      "Deaths": 2,
      "Recoveries": 79,
      "CountHidalgo": 2236,
      "CasesHidalgo": 181,
      "DeathsHidalgo": 10,
      "RecoveriesHidalgo": 75,
      "CountStarr": 112,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/28",
      "Count": 1005,
      "Cases": 30,
      "Deaths": 0,
      "Recoveries": 77,
      "CountHidalgo": 2340,
      "CasesHidalgo": 204,
      "DeathsHidalgo": 6,
      "RecoveriesHidalgo": 94,
      "CountStarr": 112,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/29",
      "Count": 1005,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2340,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 112,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/30",
      "Count": 1004,
      "Cases": 59,
      "Deaths": 0,
      "Recoveries": 60,
      "CountHidalgo": 2407,
      "CasesHidalgo": 123,
      "DeathsHidalgo": 5,
      "RecoveriesHidalgo": 51,
      "CountStarr": 112,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "10/31",
      "Count": 1004,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2407,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 112,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/01",
      "Count": 1004,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2407,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 112,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/02",
      "Count": 1059,
      "Cases": 100,
      "Deaths": 2,
      "Recoveries": 43,
      "CountHidalgo": 2517,
      "CasesHidalgo": 160,
      "DeathsHidalgo": 3,
      "RecoveriesHidalgo": 47,
      "CountStarr": 244,
      "CasesStarr": 189,
      "DeathsStarr": 7,
      "RecoveriesStarr": 50,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/03",
      "Count": 1066,
      "Cases": 54,
      "Deaths": 3,
      "Recoveries": 44,
      "CountHidalgo": 2584,
      "CasesHidalgo": 202,
      "DeathsHidalgo": 6,
      "RecoveriesHidalgo": 129,
      "CountStarr": 244,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/04",
      "Count": 1046,
      "Cases": 55,
      "Deaths": 1,
      "Recoveries": 74,
      "CountHidalgo": 2615,
      "CasesHidalgo": 130,
      "DeathsHidalgo": 6,
      "RecoveriesHidalgo": 93,
      "CountStarr": 244,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/05",
      "Count": 1101,
      "Cases": 83,
      "Deaths": 2,
      "Recoveries": 26,
      "CountHidalgo": 2701,
      "CasesHidalgo": 216,
      "DeathsHidalgo": 2,
      "RecoveriesHidalgo": 128,
      "CountStarr": 273,
      "CasesStarr": 29,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/06",
      "Count": 1057,
      "Cases": 37,
      "Deaths": 1,
      "Recoveries": 80,
      "CountHidalgo": 2725,
      "CasesHidalgo": 193,
      "DeathsHidalgo": 9,
      "RecoveriesHidalgo": 160,
      "CountStarr": 273,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/07",
      "Count": 1057,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2725,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 273,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/08",
      "Count": 1057,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2725,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 273,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/09",
      "Count": 1138,
      "Cases": 149,
      "Deaths": 2,
      "Recoveries": 66,
      "CountHidalgo": 2549,
      "CasesHidalgo": 198,
      "DeathsHidalgo": 3,
      "RecoveriesHidalgo": 371,
      "CountStarr": 270,
      "CasesStarr": 27,
      "DeathsStarr": 0,
      "RecoveriesStarr": 30,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/10",
      "Count": 1165,
      "Cases": 78,
      "Deaths": 2,
      "Recoveries": 49,
      "CountHidalgo": 2567,
      "CasesHidalgo": 147,
      "DeathsHidalgo": 8,
      "RecoveriesHidalgo": 121,
      "CountStarr": 297,
      "CasesStarr": 27,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/11",
      "Count": 1165,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2567,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 297,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/12",
      "Count": 1187,
      "Cases": 70,
      "Deaths": 3,
      "Recoveries": 45,
      "CountHidalgo": 2470,
      "CasesHidalgo": 183,
      "DeathsHidalgo": 5,
      "RecoveriesHidalgo": 275,
      "CountStarr": 330,
      "CasesStarr": 34,
      "DeathsStarr": 1,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/13",
      "Count": 1240,
      "Cases": 97,
      "Deaths": 1,
      "Recoveries": 43,
      "CountHidalgo": 2152,
      "CasesHidalgo": 199,
      "DeathsHidalgo": 4,
      "RecoveriesHidalgo": 513,
      "CountStarr": 330,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/14",
      "Count": 1240,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2152,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 330,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/15",
      "Count": 1240,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2152,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 330,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/16",
      "Count": 1348,
      "Cases": 167,
      "Deaths": 0,
      "Recoveries": 59,
      "CountHidalgo": 2282,
      "CasesHidalgo": 167,
      "DeathsHidalgo": 6,
      "RecoveriesHidalgo": 31,
      "CountStarr": 330,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/17",
      "Count": 1328,
      "Cases": 75,
      "Deaths": 3,
      "Recoveries": 92,
      "CountHidalgo": 2324,
      "CasesHidalgo": 426,
      "DeathsHidalgo": 2,
      "RecoveriesHidalgo": 382,
      "CountStarr": 330,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 92,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/18",
      "Count": 1388,
      "Cases": 74,
      "Deaths": 3,
      "Recoveries": 11,
      "CountHidalgo": 2227,
      "CasesHidalgo": 681,
      "DeathsHidalgo": 9,
      "RecoveriesHidalgo": 769,
      "CountStarr": 294,
      "CasesStarr": 55,
      "DeathsStarr": 0,
      "RecoveriesStarr": 91,
      "CountWillacy": 120,
      "CasesWillacy": 28,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/19",
      "Count": 1416,
      "Cases": 88,
      "Deaths": 2,
      "Recoveries": 58,
      "CountHidalgo": 2280,
      "CasesHidalgo": 679,
      "DeathsHidalgo": 7,
      "RecoveriesHidalgo": 619,
      "CountStarr": 327,
      "CasesStarr": 33,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 131,
      "CasesWillacy": 11,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/20",
      "Count": 1452,
      "Cases": 111,
      "Deaths": 2,
      "Recoveries": 73,
      "CountHidalgo": 2342,
      "CasesHidalgo": 719,
      "DeathsHidalgo": 8,
      "RecoveriesHidalgo": 649,
      "CountStarr": 341,
      "CasesStarr": 14,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 131,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/21",
      "Count": 1452,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2342,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 341,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 131,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/22",
      "Count": 1452,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2342,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 341,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 131,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/23",
      "Count": 1525,
      "Cases": 194,
      "Deaths": 2,
      "Recoveries": 119,
      "CountHidalgo": 1910,
      "CasesHidalgo": 729,
      "DeathsHidalgo": 4,
      "RecoveriesHidalgo": 1157,
      "CountStarr": 353,
      "CasesStarr": 88,
      "DeathsStarr": 1,
      "RecoveriesStarr": 75,
      "CountWillacy": 148,
      "CasesWillacy": 17,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/24",
      "Count": 1489,
      "Cases": 71,
      "Deaths": 3,
      "Recoveries": 104,
      "CountHidalgo": 2010,
      "CasesHidalgo": 655,
      "DeathsHidalgo": 10,
      "RecoveriesHidalgo": 545,
      "CountStarr": 353,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 157,
      "CasesWillacy": 9,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/25",
      "Count": 1482,
      "Cases": 83,
      "Deaths": 0,
      "Recoveries": 90,
      "CountHidalgo": 2156,
      "CasesHidalgo": 763,
      "DeathsHidalgo": 4,
      "RecoveriesHidalgo": 613,
      "CountStarr": 353,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 157,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/26",
      "Count": 1482,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2156,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 353,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 157,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/27",
      "Count": 1482,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2333,
      "CasesHidalgo": 612,
      "DeathsHidalgo": 2,
      "RecoveriesHidalgo": 433,
      "CountStarr": 353,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 157,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/28",
      "Count": 1482,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2333,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 353,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 157,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/29",
      "Count": 1482,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2333,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 353,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 157,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "11/30",
      "Count": 1697,
      "Cases": 296,
      "Deaths": 6,
      "Recoveries": 75,
      "CountHidalgo": 2057,
      "CasesHidalgo": 176,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 452,
      "CountStarr": 294,
      "CasesStarr": 95,
      "DeathsStarr": 2,
      "RecoveriesStarr": 152,
      "CountWillacy": 168,
      "CasesWillacy": 14,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 3
  },
  {
      "Date": "12/01",
      "Count": 1780,
      "Cases": 139,
      "Deaths": 4,
      "Recoveries": 52,
      "CountHidalgo": 1945,
      "CasesHidalgo": 526,
      "DeathsHidalgo": 13,
      "RecoveriesHidalgo": 625,
      "CountStarr": 296,
      "CasesStarr": 2,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 179,
      "CasesWillacy": 13,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 2
  },
  {
      "Date": "12/02",
      "Count": 1745,
      "Cases": 52,
      "Deaths": 2,
      "Recoveries": 85,
      "CountHidalgo": 2060,
      "CasesHidalgo": 607,
      "DeathsHidalgo": 9,
      "RecoveriesHidalgo": 483,
      "CountStarr": 296,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 179,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/03",
      "Count": 1800,
      "Cases": 124,
      "Deaths": 2,
      "Recoveries": 67,
      "CountHidalgo": 2281,
      "CasesHidalgo": 726,
      "DeathsHidalgo": 10,
      "RecoveriesHidalgo": 495,
      "CountStarr": 296,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 179,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/04",
      "Count": 1799,
      "Cases": 109,
      "Deaths": 3,
      "Recoveries": 107,
      "CountHidalgo": 2393,
      "CasesHidalgo": 508,
      "DeathsHidalgo": 8,
      "RecoveriesHidalgo": 388,
      "CountStarr": 296,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 179,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/05",
      "Count": 1799,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2393,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 296,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 179,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/06",
      "Count": 1799,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2393,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 296,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 179,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/07",
      "Count": 2005,
      "Cases": 261,
      "Deaths": 4,
      "Recoveries": 51,
      "CountHidalgo": 2493,
      "CasesHidalgo": 386,
      "DeathsHidalgo": 5,
      "RecoveriesHidalgo": 281,
      "CountStarr": 296,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 214,
      "CasesWillacy": 35,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/08",
      "Count": 1978,
      "Cases": 101,
      "Deaths": 4,
      "Recoveries": 124,
      "CountHidalgo": 2497,
      "CasesHidalgo": 346,
      "DeathsHidalgo": 7,
      "RecoveriesHidalgo": 335,
      "CountStarr": 562,
      "CasesStarr": 266,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 214,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/09",
      "Count": 1995,
      "Cases": 84,
      "Deaths": 2,
      "Recoveries": 65,
      "CountHidalgo": 2526,
      "CasesHidalgo": 330,
      "DeathsHidalgo": 8,
      "RecoveriesHidalgo": 293,
      "CountStarr": 411,
      "CasesStarr": 30,
      "DeathsStarr": 1,
      "RecoveriesStarr": 180,
      "CountWillacy": 214,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/10",
      "Count": 2060,
      "Cases": 157,
      "Deaths": 1,
      "Recoveries": 91,
      "CountHidalgo": 2545,
      "CasesHidalgo": 285,
      "DeathsHidalgo": 6,
      "RecoveriesHidalgo": 260,
      "CountStarr": 411,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 214,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/11",
      "Count": 2065,
      "Cases": 113,
      "Deaths": 3,
      "Recoveries": 105,
      "CountHidalgo": 2560,
      "CasesHidalgo": 375,
      "DeathsHidalgo": 5,
      "RecoveriesHidalgo": 355,
      "CountStarr": 411,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 214,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/12",
      "Count": 2065,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2560,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 411,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 214,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/13",
      "Count": 2065,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2560,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 411,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 214,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/14",
      "Count": 2282,
      "Cases": 294,
      "Deaths": 4,
      "Recoveries": 73,
      "CountHidalgo": 2875,
      "CasesHidalgo": 453,
      "DeathsHidalgo": 9,
      "RecoveriesHidalgo": 129,
      "CountStarr": 308,
      "CasesStarr": 126,
      "DeathsStarr": 3,
      "RecoveriesStarr": 226,
      "CountWillacy": 214,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/15",
      "Count": 2332,
      "Cases": 157,
      "Deaths": 3,
      "Recoveries": 104,
      "CountHidalgo": 2824,
      "CasesHidalgo": 447,
      "DeathsHidalgo": 7,
      "RecoveriesHidalgo": 491,
      "CountStarr": 367,
      "CasesStarr": 59,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 293,
      "CasesWillacy": 79,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/16",
      "Count": 2367,
      "Cases": 184,
      "Deaths": 4,
      "Recoveries": 145,
      "CountHidalgo": 2864,
      "CasesHidalgo": 377,
      "DeathsHidalgo": 8,
      "RecoveriesHidalgo": 329,
      "CountStarr": 367,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 309,
      "CasesWillacy": 16,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/17",
      "Count": 2414,
      "Cases": 187,
      "Deaths": 1,
      "Recoveries": 139,
      "CountHidalgo": 3326,
      "CasesHidalgo": 522,
      "DeathsHidalgo": 4,
      "RecoveriesHidalgo": 56,
      "CountStarr": 490,
      "CasesStarr": 123,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 323,
      "CasesWillacy": 14,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/18",
      "Count": 2425,
      "Cases": 139,
      "Deaths": 1,
      "Recoveries": 127,
      "CountHidalgo": 3471,
      "CasesHidalgo": 505,
      "DeathsHidalgo": 5,
      "RecoveriesHidalgo": 355,
      "CountStarr": 490,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 323,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/19",
      "Count": 2425,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 3471,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 490,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 323,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/20",
      "Count": 2425,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 3471,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 490,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 323,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/21",
      "Count": 2643,
      "Cases": 361,
      "Deaths": 5,
      "Recoveries": 138,
      "CountHidalgo": 3557,
      "CasesHidalgo": 411,
      "DeathsHidalgo": 6,
      "RecoveriesHidalgo": 319,
      "CountStarr": 490,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 349,
      "CasesWillacy": 26,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/22",
      "Count": 2626,
      "Cases": 127,
      "Deaths": 4,
      "Recoveries": 140,
      "CountHidalgo": 3290,
      "CasesHidalgo": 293,
      "DeathsHidalgo": 4,
      "RecoveriesHidalgo": 556,
      "CountStarr": 583,
      "CasesStarr": 93,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 349,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/23",
      "Count": 2611,
      "Cases": 135,
      "Deaths": 4,
      "Recoveries": 146,
      "CountHidalgo": 3266,
      "CasesHidalgo": 340,
      "DeathsHidalgo": 4,
      "RecoveriesHidalgo": 360,
      "CountStarr": 583,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 349,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/24",
      "Count": 2611,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 3344,
      "CasesHidalgo": 380,
      "DeathsHidalgo": 8,
      "RecoveriesHidalgo": 294,
      "CountStarr": 583,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 381,
      "CasesWillacy": 32,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/25",
      "Count": 2611,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 3344,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 583,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 381,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/26",
      "Count": 2611,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 3344,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 583,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 381,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/27",
      "Count": 2611,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 3344,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 583,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 381,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/28",
      "Count": 2969,
      "Cases": 446,
      "Deaths": 1,
      "Recoveries": 87,
      "CountHidalgo": 3383,
      "CasesHidalgo": 304,
      "DeathsHidalgo": 5,
      "RecoveriesHidalgo": 260,
      "CountStarr": 600,
      "CasesStarr": 244,
      "DeathsStarr": 5,
      "RecoveriesStarr": 222,
      "CountWillacy": 405,
      "CasesWillacy": 24,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/29",
      "Count": 2950,
      "Cases": 91,
      "Deaths": 5,
      "Recoveries": 105,
      "CountHidalgo": 2908,
      "CasesHidalgo": 329,
      "DeathsHidalgo": 6,
      "RecoveriesHidalgo": 798,
      "CountStarr": 589,
      "CasesStarr": 783,
      "DeathsStarr": 0,
      "RecoveriesStarr": 794,
      "CountWillacy": 405,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/30",
      "Count": 2981,
      "Cases": 113,
      "Deaths": 5,
      "Recoveries": 77,
      "CountHidalgo": 2721,
      "CasesHidalgo": 604,
      "DeathsHidalgo": 5,
      "RecoveriesHidalgo": 786,
      "CountStarr": 659,
      "CasesStarr": 70,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 54,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "12/31",
      "Count": 3035,
      "Cases": 147,
      "Deaths": 3,
      "Recoveries": 90,
      "CountHidalgo": 2711,
      "CasesHidalgo": 697,
      "DeathsHidalgo": 9,
      "RecoveriesHidalgo": 698,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/01/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/02/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/03/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/04/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/05/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/06/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/07/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/08/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/09/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/10/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/11/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/12/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/13/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/14/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/15/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/16/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/17/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/18/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/19/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/20/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/21/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/22/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/23/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/24/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/25/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/26/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/27/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/28/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/29/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/30/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "1/31/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/01/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/02/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/03/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/04/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/05/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/06/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/07/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/08/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/09/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/10/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/11/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/12/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/13/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/14/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/15/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/16/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/17/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/18/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/19/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/20/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/21/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/22/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/23/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/24/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/25/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/26/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/27/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "2/28/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/01/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/02/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/03/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/04/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/05/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/06/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/07/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/08/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/09/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/10/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/11/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/12/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/13/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/14/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/15/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/16/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/17/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/18/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/19/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/20/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/21/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/22/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/23/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/24/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/25/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/26/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/27/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/28/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/29/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/30/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "3/31/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/01/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/02/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/03/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/04/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/05/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/06/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/07/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/08/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/09/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/10/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/11/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/12/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/13/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/14/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/15/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/16/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/17/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/18/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/19/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/20/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/21/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/22/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/23/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/24/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/25/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/26/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/27/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/28/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/29/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "4/30/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/01/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/02/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/03/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/04/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/05/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  },
  {
      "Date": "5/06/2021",
      "Count": 3035,
      "Cases": 0,
      "Deaths": 0,
      "Recoveries": 0,
      "CountHidalgo": 2711,
      "CasesHidalgo": 0,
      "DeathsHidalgo": 0,
      "RecoveriesHidalgo": 0,
      "CountStarr": 659,
      "CasesStarr": 0,
      "DeathsStarr": 0,
      "RecoveriesStarr": 0,
      "CountWillacy": 459,
      "CasesWillacy": 0,
      "DeathsWillacy": 0,
      "RecoveriesWillacy": 0
  }
];

export default DEFAULT_CORONA_DATA;
