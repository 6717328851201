const HIDALGO_DEFAULT_CASES = [
  {
    "Date": "3/20",
    "Count": 0,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "3/21",
    "Count": 1,
    "Gender": {
      "Male": 0,
      "Female": 1
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 1,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 1,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "3/22",
    "Count": 1,
    "Gender": {
      "Male": 0,
      "Female": 1
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 1,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 1,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "3/24",
    "Count": 3,
    "Gender": {
      "Male": 1,
      "Female": 2
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 3,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 1,
      "McAllen": 1,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "3/25",
    "Count": 3,
    "Gender": {
      "Male": 2,
      "Female": 1
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 1,
      "41 - 60": 2,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 1,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 2,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "3/26",
    "Count": 3,
    "Gender": {
      "Male": 1,
      "Female": 2
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 1,
      "41 - 60": 1,
      "61 - 80": 1,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 1,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 2,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "3/27",
    "Count": 7,
    "Gender": {
      "Male": 4,
      "Female": 3
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 4,
      "41 - 60": 3,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 7,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "3/28",
    "Count": 9,
    "Gender": {
      "Male": 4,
      "Female": 5
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 1,
      "21 - 40": 3,
      "41 - 60": 4,
      "61 - 80": 1,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 1,
      "Mercedes": 1,
      "Alamo": 0,
      "McAllen": 3,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 1,
      "Pharr": 2,
      "Donna": 0,
      "Weslaco": 1,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "3/29",
    "Count": 1,
    "Gender": {
      "Male": 1,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 1,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 1,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "3/30",
    "Count": 10,
    "Gender": {
      "Male": 5,
      "Female": 5
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 2,
      "21 - 40": 4,
      "41 - 60": 2,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 1,
      "Mercedes": 1,
      "Alamo": 0,
      "McAllen": 2,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 2,
      "Pharr": 2,
      "Donna": 1,
      "Weslaco": 1,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "3/31",
    "Count": 8,
    "Gender": {
      "Male": 3,
      "Female": 5
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 2,
      "21 - 40": 4,
      "41 - 60": 2,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 1,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 3,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 4,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/01",
    "Count": 16,
    "Gender": {
      "Male": 6,
      "Female": 10
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 3,
      "21 - 40": 7,
      "41 - 60": 3,
      "61 - 80": 2,
      "81+": 1
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 1,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 3,
      "Alamo": 0,
      "McAllen": 2,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 2,
      "Pharr": 3,
      "Donna": 2,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/02",
    "Count": 17,
    "Gender": {
      "Male": 9,
      "Female": 8
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 4,
      "21 - 40": 8,
      "41 - 60": 5,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 1,
      "Alamo": 0,
      "McAllen": 2,
      "Edinburg": 4,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 3,
      "Donna": 4,
      "Weslaco": 2,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/03",
    "Count": 7,
    "Gender": {
      "Male": 1,
      "Female": 6
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 3,
      "41 - 60": 4,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 1,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 2,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 4,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/05",
    "Count": 13,
    "Gender": {
      "Male": 8,
      "Female": 5
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 1,
      "21 - 40": 5,
      "41 - 60": 3,
      "61 - 80": 4,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 1,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 1,
      "Mercedes": 3,
      "Alamo": 1,
      "McAllen": 2,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 1,
      "Mission": 2,
      "Pharr": 0,
      "Donna": 1,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/06",
    "Count": 14,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 7,
      "41 - 60": 5,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 2,
      "Mercedes": 0,
      "Alamo": 3,
      "McAllen": 1,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 3,
      "Pharr": 1,
      "Donna": 0,
      "Weslaco": 3,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/07",
    "Count": 15,
    "Gender": {
      "Male": 7,
      "Female": 8
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 1,
      "21 - 40": 5,
      "41 - 60": 8,
      "61 - 80": 1,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 3,
      "Mercedes": 1,
      "Alamo": 1,
      "McAllen": 3,
      "Edinburg": 2,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 2,
      "Donna": 1,
      "Weslaco": 1,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/08",
    "Count": 11,
    "Gender": {
      "Male": 4,
      "Female": 7
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 4,
      "41 - 60": 6,
      "61 - 80": 1,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 2,
      "Mercedes": 1,
      "Alamo": 1,
      "McAllen": 2,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 2,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 2,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/09",
    "Count": 7,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/10",
    "Count": 35,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/11",
    "Count": 7,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/12",
    "Count": 2,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/13",
    "Count": 8,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/14",
    "Count": 19,
    "Gender": {
      "Male": 4,
      "Female": 15
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 1,
      "21 - 40": 11,
      "41 - 60": 5,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 1,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 1,
      "Alamo": 2,
      "McAllen": 3,
      "Edinburg": 3,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 3,
      "Pharr": 0,
      "Donna": 1,
      "Weslaco": 5,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/15",
    "Count": 8,
    "Gender": {
      "Male": 5,
      "Female": 3
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 4,
      "41 - 60": 2,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 1,
      "Alamo": 1,
      "McAllen": 1,
      "Edinburg": 2,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 1,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 2,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/16",
    "Count": 10,
    "Gender": {
      "Male": 7,
      "Female": 3
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 1,
      "21 - 40": 2,
      "41 - 60": 5,
      "61 - 80": 1,
      "81+": 1
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 2,
      "Mercedes": 0,
      "Alamo": 1,
      "McAllen": 1,
      "Edinburg": 2,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 1,
      "Pharr": 1,
      "Donna": 0,
      "Weslaco": 2,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/17",
    "Count": 7,
    "Gender": {
      "Male": 5,
      "Female": 2
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 2,
      "41 - 60": 4,
      "61 - 80": 1,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 1,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 1,
      "McAllen": 1,
      "Edinburg": 3,
      "Palmview": 1,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/18",
    "Count": 13,
    "Gender": {
      "Male": 9,
      "Female": 4
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 1,
      "21 - 40": 5,
      "41 - 60": 5,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 1,
      "Edinburg": 6,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 1,
      "Weslaco": 3,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/19",
    "Count": 6,
    "Gender": {
      "Male": 4,
      "Female": 2
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 4,
      "41 - 60": 2,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 2,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 1,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 2,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/20",
    "Count": 7,
    "Gender": {
      "Male": 4,
      "Female": 3
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 4,
      "21 - 40": 2,
      "41 - 60": 1,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 3,
      "McAllen": 0,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 3,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/21",
    "Count": 7,
    "Gender": {
      "Male": 4,
      "Female": 3
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 6,
      "41 - 60": 0,
      "61 - 80": 1,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 2,
      "Edinburg": 3,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 2,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/22",
    "Count": 6,
    "Gender": {
      "Male": 1,
      "Female": 5
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 3,
      "41 - 60": 1,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 1,
      "McAllen": 0,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 2,
      "Pharr": 2,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/23",
    "Count": 9,
    "Gender": {
      "Male": 5,
      "Female": 4
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 3,
      "21 - 40": 4,
      "41 - 60": 1,
      "61 - 80": 0,
      "81+": 1
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 1,
      "San Juan": 0,
      "Mercedes": 2,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 2,
      "Pharr": 2,
      "Donna": 1,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/24",
    "Count": 9,
    "Gender": {
      "Male": 4,
      "Female": 5
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 1,
      "21 - 40": 5,
      "41 - 60": 1,
      "61 - 80": 1,
      "81+": 1
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 1,
      "Elsa": 1,
      "San Juan": 1,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 4,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 1,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 1,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/25",
    "Count": 4,
    "Gender": {
      "Male": 1,
      "Female": 3
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 2,
      "41 - 60": 2,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 1,
      "Palmview": 1,
      "La Joya": 0,
      "Mission": 1,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 1,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/26",
    "Count": 5,
    "Gender": {
      "Male": 4,
      "Female": 1
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 3,
      "41 - 60": 1,
      "61 - 80": 1,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 1,
      "Edinburg": 3,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 1,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/27",
    "Count": 2,
    "Gender": {
      "Male": 0,
      "Female": 1
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 1,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 1,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/28",
    "Count": 5,
    "Gender": {
      "Male": 1,
      "Female": 4
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 5,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 2,
      "Mercedes": 1,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 1,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/29",
    "Count": 9,
    "Gender": {
      "Male": 7,
      "Female": 2
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 5,
      "41 - 60": 3,
      "61 - 80": 1,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 1,
      "Mercedes": 0,
      "Alamo": 1,
      "McAllen": 0,
      "Edinburg": 2,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 4,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 1,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/30",
    "Count": 10,
    "Gender": {
      "Male": 6,
      "Female": 4
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 6,
      "41 - 60": 2,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 1,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 1,
      "Edinburg": 3,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 2,
      "Pharr": 1,
      "Donna": 1,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/01",
    "Count": 5,
    "Gender": {
      "Male": 2,
      "Female": 3
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 2,
      "41 - 60": 2,
      "61 - 80": 1,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 1,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 3,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/02",
    "Count": 1,
    "Gender": {
      "Male": 1,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 1,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 1,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/03",
    "Count": 1,
    "Gender": {
      "Male": 0,
      "Female": 1
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 1,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/04",
    "Count": 12,
    "Gender": {
      "Male": 4,
      "Female": 8
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 3,
      "41 - 60": 7,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 5,
      "McAllen": 0,
      "Edinburg": 2,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 3,
      "Donna": 1,
      "Weslaco": 1,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/06",
    "Count": 6,
    "Gender": {
      "Male": 3,
      "Female": 3
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 1,
      "41 - 60": 3,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 1,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 1,
      "Mission": 1,
      "Pharr": 1,
      "Donna": 2,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/07",
    "Count": 9,
    "Gender": {
      "Male": 5,
      "Female": 4
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 4,
      "41 - 60": 2,
      "61 - 80": 3,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 2,
      "McAllen": 0,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 1,
      "Donna": 1,
      "Weslaco": 1,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/08",
    "Count": 9,
    "Gender": {
      "Male": 5,
      "Female": 4
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 5,
      "41 - 60": 2,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 1,
      "Mercedes": 1,
      "Alamo": 2,
      "McAllen": 0,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 3,
      "Donna": 0,
      "Weslaco": 1,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/09",
    "Count": 10,
    "Gender": {
      "Male": 4,
      "Female": 6
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 5,
      "41 - 60": 1,
      "61 - 80": 4,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 1,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 1,
      "Mercedes": 1,
      "Alamo": 4,
      "McAllen": 0,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 1,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 1,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/10",
    "Count": 2,
    "Gender": {
      "Male": 1,
      "Female": 1
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 1,
      "41 - 60": 1,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 1,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 1,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/11",
    "Count": 5,
    "Gender": {
      "Male": 2,
      "Female": 3
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 4,
      "41 - 60": 1,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 1,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 2,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/12",
    "Count": 9,
    "Gender": {
      "Male": 5,
      "Female": 4
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 7,
      "41 - 60": 2,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 1,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 4,
      "Alamo": 0,
      "McAllen": 2,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 1,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/13",
    "Count": 6,
    "Gender": {
      "Male": 5,
      "Female": 1
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 2,
      "41 - 60": 1,
      "61 - 80": 3,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 2,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/14",
    "Count": 8,
    "Gender": {
      "Male": 3,
      "Female": 5
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 6,
      "41 - 60": 0,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 2,
      "Edinburg": 1,
      "Palmview": 1,
      "La Joya": 0,
      "Mission": 2,
      "Pharr": 1,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/15",
    "Count": 8,
    "Gender": {
      "Male": 4,
      "Female": 4
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 1,
      "21 - 40": 2,
      "41 - 60": 5,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 2,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 2,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 1,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 2,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/17",
    "Count": 15,
    "Gender": {
      "Male": 7,
      "Female": 8
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 2,
      "21 - 40": 9,
      "41 - 60": 3,
      "61 - 80": 1,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 1,
      "McAllen": 1,
      "Edinburg": 6,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 1,
      "Donna": 2,
      "Weslaco": 2,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/18",
    "Count": 8,
    "Gender": {
      "Male": 4,
      "Female": 4
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 1,
      "21 - 40": 4,
      "41 - 60": 2,
      "61 - 80": 1,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 1,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 1,
      "Pharr": 3,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/19",
    "Count": 9,
    "Gender": {
      "Male": 5,
      "Female": 4
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 3,
      "21 - 40": 6,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 1,
      "Pharr": 0,
      "Donna": 1,
      "Weslaco": 5,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/20",
    "Count": 10,
    "Gender": {
      "Male": 4,
      "Female": 6
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 1,
      "21 - 40": 3,
      "41 - 60": 6,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 2,
      "Edinburg": 2,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 3,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 2,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/21",
    "Count": 5,
    "Gender": {
      "Male": 0,
      "Female": 5
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 1,
      "41 - 60": 2,
      "61 - 80": 2,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 2,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 1,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/22",
    "Count": 10,
    "Gender": {
      "Male": 5,
      "Female": 5
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 2,
      "21 - 40": 7,
      "41 - 60": 1,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 2,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 1,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 1,
      "Pharr": 3,
      "Donna": 1,
      "Weslaco": 2,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  }
];

export default HIDALGO_DEFAULT_CASES;
