import React from 'react';
import ReactDOM from 'react-dom';

import { HashRouter } from "react-router-dom";

import Routes from './containers/Routes';
import history from './utils/history';

import * as serviceWorker from './serviceWorker';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import './index.css';

ReactDOM.render(
  <HashRouter history={history}>
    <Routes />
  </HashRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
