const CAMERON_DEFAULT_RECOVERIES = [
  {
    "Date": "4/02",
    "Count": 0,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/03",
    "Count": 10,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/04",
    "Count": 1,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/07",
    "Count": 7,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/08",
    "Count": 5,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/09",
    "Count": 22,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/11",
    "Count": 17,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/13",
    "Count": 8,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/14",
    "Count": 3,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/15",
    "Count": 11,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/16",
    "Count": 12,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/17",
    "Count": 6,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/18",
    "Count": 6,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/20",
    "Count": 8,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/21",
    "Count": 11,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/22",
    "Count": 21,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/23",
    "Count": 5,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/24",
    "Count": 6,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/27",
    "Count": 4,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/28",
    "Count": 8,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/29",
    "Count": 7,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "4/30",
    "Count": 9,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/01",
    "Count": 4,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/02",
    "Count": 11,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/04",
    "Count": 2,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/05",
    "Count": 10,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/06",
    "Count": 26,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/07",
    "Count": 7,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/08",
    "Count": 9,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/09",
    "Count": 9,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/11",
    "Count": 18,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/12",
    "Count": 14,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/13",
    "Count": 33,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/14",
    "Count": 22,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/15",
    "Count": 15,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/16",
    "Count": 31,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/18",
    "Count": 2,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/19",
    "Count": 10,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/20",
    "Count": 7,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/21",
    "Count": 21,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  },
  {
    "Date": "5/22",
    "Count": 17,
    "Gender": {
      "Male": 0,
      "Female": 0
    },
    "Transmission": {
      "Travel": 0,
      "Community": 0,
      "Linked To Previous Case": 0
    },
    "Ages": {
      "0 - 20": 0,
      "21 - 40": 0,
      "41 - 60": 0,
      "61 - 80": 0,
      "81+": 0
    },
    "Cities": {
      "Harlingen": 0,
      "Brownsville": 0,
      "Rancho Viejo": 0,
      "Rio Hondo": 0,
      "Los Fresnos": 0,
      "La Feria": 0,
      "San Benito": 0,
      "Laguna Vista": 0,
      "Santa Rosa": 0,
      "Port Isabel": 0,
      "Palm Valley": 0,
      "Hidalgo": 0,
      "Edcouch": 0,
      "Elsa": 0,
      "San Juan": 0,
      "Mercedes": 0,
      "Alamo": 0,
      "McAllen": 0,
      "Edinburg": 0,
      "Palmview": 0,
      "La Joya": 0,
      "Mission": 0,
      "Pharr": 0,
      "Donna": 0,
      "Weslaco": 0,
      "Rio Grande City": 0,
      "Escobares": 0
    }
  }
];

export default CAMERON_DEFAULT_RECOVERIES;
